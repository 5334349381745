import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);
  }

  init() {
    let module = this;
    if ($(this.el).find('img[loading]').length) {
      $.each($(this.el).find('img[loading]'), function (
        indexInArray,
        valueOfElement
      ) {
        valueOfElement.onload = () => {
          setTimeout(() => {
            module.call('update', false, 'Scroll'); // update Locomotive Scroll
          }, 500);
        };
      });
    }
  }
}
